<template>
  <div class="container-list">
    <div class="page-container payments">
      <div class="topHeader">
        <searchBar style="margin-right:15px" :search="search" @update:search="search = $event" />
        <button class="checkpayment-btn--icon" :class="{ disabled: syncRequest || !syncRequestUpdate }" :disabled="syncRequest || !syncRequestUpdate" @click="syncData">
          <span v-if="syncRequestUpdate">Aggiorna</span>
          <svg v-else class="rotate-icon" style="width: 24px; height: 24px" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M12,6V9L16,5L12,1V4A8,8 0 0,0 4,12C4,13.57 4.46,15.03 5.24,16.26L6.7,14.8C6.25,13.97 6,13 6,12A6,6 0 0,1 12,6M18.76,7.74L17.3,9.2C17.74,10.04 18,11 18,12A6,6 0 0,1 12,18V15L8,19L12,23V20A8,8 0 0,0 20,12C20,10.43 19.54,8.97 18.76,7.74Z"
            />
          </svg>
        </button>
      </div>
      <div class="table" :class="pages > 1 ? 'pagination' : ''">
        <table class="panel-table" cellspacing="0">
          <thead>
            <th style="width: 25px" />
            <th v-if="false" style="width: 50px">
              <input id="mainCheckboxUser" type="checkbox">
            </th>
            <th class="orderable" @click="changeOrder('Nome')">
              <div class="text-left">
                <span>Utente</span>
                <font-awesome-icon v-if="filters.order.field == 'Nome'" :icon="filters.order.asc ? 'arrow-down-a-z' : 'arrow-down-z-a'" />
              </div>
            </th>
            <th class="orderable" @click="changeOrder('Email')">
              <div>
                <span>Email</span>
                <font-awesome-icon v-if="filters.order.field == 'Email'" :icon="filters.order.asc ? 'arrow-down-a-z' : 'arrow-down-z-a'" />
              </div>
            </th>
            <th class="orderable text-center" @click="changeOrder('Data richiesta')">
              <div>
                <span>Data richiesta</span>
                <font-awesome-icon v-if="filters.order.field == 'Data richiesta'" :icon="filters.order.asc ? 'arrow-down-1-9' : 'arrow-down-9-1'" />
              </div>
            </th>
            <th class="orderable text-center" @click="changeOrder('Importo')">
              <div>
                <span>Importo</span>
                <font-awesome-icon v-if="filters.order.field == 'Importo'" :icon="filters.order.asc ? 'arrow-down-1-9' : 'arrow-down-9-1'" />
              </div>
            </th>
            <th />
            <th style="width: 50px" />
          </thead>
          <tbody class="table-body">
            <slot v-for="(user, index) in getPaginateUsers" :id="user.email + 'panel' + index" :key="user.lastName + '_' + user.email + '_' + index">
              <tr class="cursor-pointer" @click="toggleMasterDetail(user.email)">
                <td style="width: 25px">
                  <!-- Pagamenti aggregati -->
                  <svg
                    v-if="
                      user.payments.find((payment) => {
                        return user.payments.indexOf(payment) > 0 && payment.state === 'created' && !payment.payoutEnabled
                      })
                    "
                    style="width: 24px; height: 24px"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#a4c84d"
                      d="M3,6H21V18H3V6M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M7,8A2,2 0 0,1 5,10V14A2,2 0 0,1 7,16H17A2,2 0 0,1 19,14V10A2,2 0 0,1 17,8H7Z"
                    />
                  </svg>
                  <!-- Pagamenti in processo da paypal (in attesa)-->
                  <svg
                    v-else-if="
                      user.payments.filter((payment) => user.payments.indexOf(payment) > 0 && !payment[0]).find((payment) => payment.state === 'created' && payment.payoutEnabled)
                    "
                    class="rotate-icon"
                    style="width: 24px; height: 24px"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="orange"
                      d="M12,18A6,6 0 0,1 6,12C6,11 6.25,10.03 6.7,9.2L5.24,7.74C4.46,8.97 4,10.43 4,12A8,8 0 0,0 12,20V23L16,19L12,15M12,4V1L8,5L12,9V6A6,6 0 0,1 18,12C18,13 17.75,13.97 17.3,14.8L18.76,16.26C19.54,15.03 20,13.57 20,12A8,8 0 0,0 12,4Z"
                    />
                  </svg>
                  <!-- Pagamenti fallito -->
                  <svg
                    v-else-if="user.payments.filter((payment) => user.payments.indexOf(payment) > 0 && !payment[0]).find((payment) => payment.state === 'failed')"
                    style="width: 24px; height: 24px"
                    viewBox="0 0 24 24"
                  >
                    <path fill="#dc3545" d="M13,13H11V7H13M13,17H11V15H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                  </svg>
                </td>
                <!-- <input :id="'collapsible' + index" class="toggle" type="checkbox" :checked="checked === company.coid" @change.stop.prevent="getCompany(company.coid)" /> -->
                <td v-if="false" class="text-center">
                  <input
                    :id="'collapsible' + index"
                    class="toggle"
                    type="checkbox"
                    :checked="usersSelected.indexOf(user.email) !== -1"
                    @change="select(company.coid)"
                    @click.stop=""
                  >
                </td>
                <td>{{ user.firstName }} {{ user.lastName }}</td>
                <td>
                  <div :id="'email' + user.email">
                    <Tooltip :text="user.email" :disabled="user.email.length < 27">
                      {{ getLabel(user.email) }}
                    </Tooltip>
                  </div>
                </td>
                <td class="text-center">
                  <div>{{ getDate(user.payments[0].creationDate) }}</div>
                </td>
                <td class="text-center">
                  <div>{{ user.payments[0].amount }} €</div>
                </td>
                <td class="text-center">
                  <material-button
                    v-if="user.payments[0].state === 'created' && !user.payments[0].payoutEnabled && permissions.canUse('PAYOUT_REQUEST_CONFIRM')"
                    class="checkpayment-btn"
                    text="Approva pagamento"
                    type="info"
                    @click.stop.prevent="openNewPaymentModal(user, user.payments[0])"
                  />
                  <span v-else-if="!(user.payments[0].state === 'created' && !user.payments[0].payoutEnabled)">Pagato</span>
                </td>
                <td class="chevron" style="width: 50px">
                  <svg :class="{ rotate: collapsiblePanelOpened === user.email }" style="width: 24px; height: 24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                  </svg>
                </td>
              </tr>

              <tr v-if="collapsiblePanelOpened === user.email" class="collapsible-content">
                <td colspan="7" class="content-inner" style="padding: 0 !important">
                  <div style="padding: 20px 30px 10px 30px; text-align: left">
                    <span v-if="user.payments.length > 1">Storico pagamenti</span>
                    <span v-else>
                      <div class="payment-alert">
                        <span class="payment-badge">
                          <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                            <path
                              fill="currentColor"
                              d="M21 11.11V5C21 3.9 20.11 3 19 3H14.82C14.4 1.84 13.3 1 12 1S9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.11 3.9 21 5 21H11.11C12.37 22.24 14.09 23 16 23C19.87 23 23 19.87 23 16C23 14.09 22.24 12.37 21 11.11M12 3C12.55 3 13 3.45 13 4S12.55 5 12 5 11 4.55 11 4 11.45 3 12 3M5 19V5H7V7H17V5H19V9.68C18.09 9.25 17.08 9 16 9H7V11H11.1C10.5 11.57 10.04 12.25 9.68 13H7V15H9.08C9.03 15.33 9 15.66 9 16C9 17.08 9.25 18.09 9.68 19H5M16 21C13.24 21 11 18.76 11 16S13.24 11 16 11 21 13.24 21 16 18.76 21 16 21M16.5 16.25L19.36 17.94L18.61 19.16L15 17V12H16.5V16.25Z"
                            />
                          </svg>
                        </span>
                        <span>Nessuno storico pagamenti!</span>
                      </div>
                    </span>
                  </div>
                  <div v-if="user.payments.length > 1" class="table-container">
                    <table class="content-table history">
                      <tr class="history-tr-header">
                        <th @click="changeOrderInside('Data')">
                          <span>Data</span>
                          <font-awesome-icon v-if="filters.order.fieldInside == 'Data'" :icon="filters.order.ascInside ? 'arrow-down-1-9' : 'arrow-down-9-1'" />
                        </th>
                        <th @click="changeOrderInside('Importo')">
                          <span>Importo</span>
                          <font-awesome-icon v-if="filters.order.fieldInside == 'Importo'" :icon="filters.order.ascInside ? 'arrow-down-1-9' : 'arrow-down-9-1'" />
                        </th>
                        <th @click="changeOrderInside('Versato')">
                          <span>Versato a</span>
                          <font-awesome-icon v-if="filters.order.fieldInside == 'Versato'" :icon="filters.order.ascInside ? 'arrow-down-a-z' : 'arrow-down-a-z'" />
                        </th>
                        <th @click="changeOrderInside('Pagato')">
                          <span>Pagato il</span>
                          <font-awesome-icon v-if="filters.order.fieldInside == 'Pagato'" :icon="filters.order.ascInside ? 'arrow-down-1-9' : 'arrow-down-9-1'" />
                        </th>
                        <th @click="changeOrderInside('Stato')">
                          <span>Stato</span>
                          <font-awesome-icon v-if="filters.order.fieldInside == 'Stato'" :icon="filters.order.ascInside ? 'arrow-down-a-z' : 'arrow-down-z-a'" />
                        </th>
                      </tr>
                      <tr
                        v-for="(payment, i) in user.payments.filter((payment) => user.payments.indexOf(payment) > 0)"
                        :key="user.name + '_payments_' + i"
                        class="history-tr-content"
                      >
                        <td>{{ getDate(payment.creationDate) }}</td>
                        <td>
                          <span>{{ payment.amount }} €</span>
                        </td>
                        <td>
                          <Tooltip :text="payment.emailReceiver" :disabled="payment.emailReceiver.length < 27">
                            {{ getLabel(payment.emailReceiver) }}
                          </Tooltip>
                        </td>
                        <td>{{ getDate(payment.updateDate) }}</td>
                        <td>
                          <span
                            class="dot"
                            :class="
                              payment.state === 'paid' || payment.state === 'paid_unclaimed'
                                ? 'dot-color1'
                                : payment.state === 'failed'
                                  ? 'dot-color2'
                                  : payment.state === 'created' && payment.payoutEnabled
                                    ? 'dot-color0'
                                    : ''
                            "
                          />
                          <span v-if="payment.state === 'created' && payment.payoutEnabled">In attesa</span>
                          <span v-if="payment.state === 'failed'">Rifiutato</span>
                          <span v-if="payment.state === 'paid' || payment.state === 'paid_unclaimed'">Pagato</span>
                          <material-button
                            v-if="payment.state === 'created' && !payment.payoutEnabled && permissions.canUse('PAYOUT_REQUEST_CONFIRM')"
                            class="checkpayment-btn"
                            text="Approva pagamento"
                            type="info"
                            @click.stop.prevent="openNewPaymentModal(user, payment)"
                          />
                        </td>
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>
            </slot>
          </tbody>
        </table>
      </div>
      <div v-if="pages > 1" class="pagination">
        <v-pagination v-model="page" :pages="pages" :range-size="1" active-color="#DCEDFF" />
      </div>
    </div>

    <div v-if="openNewPayment && userToPay?.user?.firstName" class="dialog">
      <div class="dialog-content">
        <div class="dialog-header">
          <div class="title">
            Sei sicuro di voler approvare il pagamento?
          </div>
          <div class="close" @click="closeNewPayment()">
            <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
            </svg>
          </div>
        </div>
        <div class="dialog-text" style="margin-top: 0">
          <p>Verranno accreditati {{ userToPay.payment.amount }}</p>
          <p>a {{ userToPay.user.firstName }} {{ userToPay.user.lastName }}</p>
          <p>sull'account {{ userToPay.payment.emailReceiver }}</p>
        </div>

        <div>
          <material-button text="Approva il pagamento" type="info" @click="payUser()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import 'moment/locale/it'
import { sbapibackoffice } from '@/api'
import { spinnerStore } from '@/stores/spinner'
import { navbarStore } from '@/stores/navbar'
import { reactive } from '@vue/reactivity'
import Tooltip from '../components/Tooltip.vue'
import { toRefs, inject, onBeforeMount, onMounted, computed } from '@vue/runtime-core'
import searchBar from '../components/searchBarList.vue'
import VPagination from '@hennge/vue3-pagination'
import { permissionsStore } from '@/stores/permissions'

export default {
  components: {
    searchBar,
    VPagination,
    Tooltip
  },
  setup() {
    const toast = inject('$toast')
    const permissions = permissionsStore()
    const state = reactive({
      syncRequest: false,
      syncRequestUpdate: true,
      spinner: spinnerStore(),
      navbar: navbarStore(),
      search: '',
      page: 1,
      itemsPerPage: 10,
      usersSelected: [],
      usersToPayFiltred: [],
      usersToPay: [],
      filters: {
        order: {
          field: 'Data richiesta',
          asc: false,
          fieldInside: 'Pagato',
          ascInside: true
        }
      },
      collapsiblePanelOpened: null,
      openNewPayment: false,
      userToPay: {}
    })
    const spinner = spinnerStore()
    onBeforeMount(() => {
      spinner.show()
      syncData()
      spinner.hide()
    })

    onMounted(() => {
      state.navbar.title = 'Gestione Pagamenti'
    })
    const pages = computed(() => {
      return Math.ceil(state.usersToPayFiltred.length / state.itemsPerPage)
    })
    const selectAllUsers = () => {
      if (state.usersToPay.length === state.usersToPay.length) {
        state.usersToPay = []
      } else {
        state.usersToPay = [...new Set(state.usersToPay.map((user) => user.id))]
      }
    }
    const changeOrder = (orderBy) => {
      if (state.filters.order.field == orderBy) {
        state.filters.order.asc = !state.filters.order.asc
      } else {
        state.filters.order.field = orderBy
        state.filters.order.asc = true
      }
    }
    const changeOrderInside = (orderBy) => {
      if (state.filters.order.fieldInside == orderBy) {
        state.filters.order.ascInside = !state.filters.order.ascInside
      } else {
        state.filters.order.fieldInside = orderBy
        state.filters.order.ascInside = true
      }
    }
    const getPaginateUsers = computed(() => {
      let arr = []
      if (state.search != '') {
        arr = state.usersToPay.filter((user) => {
          return JSON.stringify(user).toLowerCase().includes(state.search.toLowerCase())
        })
      } else {
        arr = state.usersToPay
      }
      if (state.filters.order.fieldInside) {
        arr.forEach((record) => {
          record.payments = record.payments.sort((first, second) => {
            switch (state.filters.order.fieldInside) {
              case 'Data':
                if (moment(first.creationDate, 'YYYY-MM-DDTHH:mm:ss').isBefore(moment(second.creationDate, 'YYYY-MM-DDTHH:mm:ss'))) {
                  return state.filters.order.ascInside ? -1 : 1
                }
                if (moment(first.creationDate, 'YYYY-MM-DDTHH:mm:ss').isAfter(moment(second.creationDate, 'YYYY-MM-DDTHH:mm:ss'))) {
                  return state.filters.order.ascInside ? 1 : -1
                }
                return 0
              case 'Pagato':
                if (!first.updateDate) {
                  return state.filters.order.ascInside ? -1 : 1
                }
                if (moment(first.updateDate, 'YYYY-MM-DDTHH:mm:ss').isBefore(moment(second.updateDate, 'YYYY-MM-DDTHH:mm:ss'))) {
                  return state.filters.order.ascInside ? -1 : 1
                }
                if (moment(first.updateDate, 'YYYY-MM-DDTHH:mm:ss').isAfter(moment(second.updateDate, 'YYYY-MM-DDTHH:mm:ss'))) {
                  return state.filters.order.ascInside ? 1 : -1
                }
                return 0
              case 'Versato':
                if (first.email < second.email) {
                  return state.filters.order.ascInside ? -1 : 1
                }
                if (first.email > second.email) {
                  return state.filters.order.ascInside ? 1 : -1
                }
                return 0
            }
          })
        })
      }
      return arr.sort((first, second) => {
        switch (state.filters.order.field) {
          case 'Nome':
            let a = first.firstName + ' ' + first.lastName
            let b = second.firstName + ' ' + second.lastName
            if (a < b) {
              return state.filters.order.asc ? -1 : 1
            }
            if (a > b) {
              return state.filters.order.asc ? 1 : -1
            }
            return 0
          case 'Email':
            if (first.email < second.email) {
              return state.filters.order.asc ? -1 : 1
            }
            if (first.email > second.email) {
              return state.filters.order.asc ? 1 : -1
            }
            return 0
          case 'Importo':
            if (Number(first.payments[0].amount) < Number(second.payments[0].amount)) {
              return state.filters.order.asc ? -1 : 1
            }
            if (Number(first.payments[0].amount) > Number(second.payments[0].amount)) {
              return state.filters.order.asc ? 1 : -1
            }
            return 0
          case 'Data richiesta':
            if (moment(first.payments[0].creationDate, 'YYYY-MM-DDTHH:mm:ss').isBefore(moment(second.payments[0].creationDate, 'YYYY-MM-DDTHH:mm:ss'))) {
              return state.filters.order.asc ? -1 : 1
            }
            if (moment(first.payments[0].creationDate, 'YYYY-MM-DDTHH:mm:ss').isAfter(moment(second.payments[0].creationDate, 'YYYY-MM-DDTHH:mm:ss'))) {
              return state.filters.order.asc ? 1 : -1
            }
            return 0
        }
      })
    })
    const userSelected = reactive({})
    const toggleMasterDetail = (email) => {
      if (state.collapsiblePanelOpened === email) state.collapsiblePanelOpened = null
      else {
        state.collapsiblePanelOpened = email
      }
    }
    const select = (email) => {
      let index = state.usersSelected.indexOf(email)
      if (index === -1) state.usersSelected.push(email)
      else state.usersSelected.splice(index, 1)
    }
    const getDate = (date) => {
      return date ? moment(date, 'YYYY-MM-DDTHH:mm:ss').format('DD-MM-YYYY') : '-'
    }
    const openNewPaymentModal = (user, payment) => {
      state.openNewPayment = true
      state.userToPay = {
        user: user,
        payment: payment
      }
    }
    const closeNewPayment = () => {
      state.openNewPayment = false
      state.userToPay = {}
    }
    const payUser = () => {
      spinner.show()
      sbapibackoffice
        .put('/referral/payment', { id: state.userToPay.payment.id, payoutEnabled: !state.userToPay.payment.payoutEnabled })
        .then((response) => {
          if (response.status === 200) {
            state.usersToPay.forEach((user) => {
              user.payments.forEach((payment) => {
                if (payment.id === state.userToPay.payment.id) {
                  payment.payoutEnabled = true
                }
              })
            })
            let count = []
            state.usersToPay.forEach((p) => {
              p.payments.forEach((pay) => {
                if (pay.state === 'created' && !pay.payoutEnabled) {
                  count.push(p)
                }
              })
            })
            window.dispatchEvent(
              new CustomEvent('storage-payments', {
                detail: {
                  reducePayment: count.length
                }
              })
            )
          }
        })
        .catch((err) => {
          let error = ''
          switch (err.response.data.messageCode) {
            case 'P2P-020':
              error = 'Il campo \'payoutEnabled\'  deve essere uguale a true'
              break
            case 'P2P-021':
              error = 'Il pagamento paypal con ID ' + state.userToPay.payment.id + ' non esiste'
              break
            case 'P2P-022':
              error = 'Condizioni errate per l\'approvazione del pagamento. State: ' + state.userToPay.state + ' Flag payout_enabled = ' + state.userToPay.payment.payoutEnabled
              break
          }
          toast.error('Errore ' + err.response.data.messageCode + ': ' + error)
        })
        .finally(() => {
          closeNewPayment()
          spinner.hide()
        })
    }
    const getLabel = (email) => {
      let mail = email
      if (email.length > 30) {
        mail = email.slice(0, 27)
        mail += '...'
      }
      return mail
    }
    const syncData = () => {
      state.syncRequest = true
      state.syncRequestUpdate = false
      sbapibackoffice
        .get('/referrals/payment')
        .then((response) => {
          let payments = response.data.reduce((k, v) => {
            k[v.email] = k[v.email] || []
            k[v.email].push(v)
            return k
          }, Object.create(null))
          let paymentsFormatted = []
          for (let key in payments) {
            let user = payments[key]
            paymentsFormatted.push({
              email: key,
              firstName: user[0].firstName,
              lastName: user[0].lastName,
              uid: user[0].uid
            })
            for (let payment in payments[key]) {
              if (!paymentsFormatted[paymentsFormatted.length - 1].payments) {
                paymentsFormatted[paymentsFormatted.length - 1].payments = []
              }
              paymentsFormatted[paymentsFormatted.length - 1].payments.push({
                amount: user[payment].amount,
                id: user[payment].id,
                creationDate: user[payment].creationDate,
                currency: user[payment].currency,
                payoutEnabled: user[payment].payoutEnabled,
                state: user[payment].state,
                emailReceiver: user[payment].emailReceiver,
                updateDate: user[payment].updateDate
              })
            }
          }
          Object.assign(state.usersToPay, [])
          Object.assign(state.usersToPay, paymentsFormatted)
          let count = []
          state.usersToPay.forEach((p) => {
            p.payments.forEach((pay) => {
              if (pay.state === 'created' && !pay.payoutEnabled) {
                count.push(p)
              }
            })
          })
          window.dispatchEvent(
            new CustomEvent('storage-payments', {
              detail: {
                reducePayment: count.length
              }
            })
          )
        })
        .catch((err) => {
          toast.error('Non è stato possibile scaricare la lista dei pagamenti: ', err)
        })
        .finally(
          setTimeout(() => {
            state.syncRequest = false
          }, 5000)
        )
      setTimeout(() => {
        state.syncRequestUpdate = true
      }, 1500)
    }
    return {
      ...toRefs(state),
      syncData,
      pages,
      selectAllUsers,
      changeOrder,
      getPaginateUsers,
      userSelected,
      toggleMasterDetail,
      select,
      getDate,
      openNewPaymentModal,
      payUser,
      getLabel,
      closeNewPayment,
      changeOrderInside,
      permissions
    }
  }
}
</script>

<style lang="scss" scoped>
@use '../assets/scss/table';
@import '../assets/scss/lists.scss';
@import '../assets/scss/dialog.scss'; // general dialog styles
.payments .table table.panel-table th svg path {
	fill: var(--accented) !important;
}
.page-container.payments table tbody.table-body th svg {
	color: #a4c84d;
	margin-left: 5px;
	cursor: pointer;
	&:hover {
		transform: scale(1.15);
		transition: all 0.5s ease-in-out;
	}
}
table .history-tr-header th:first-child,
table.history tr.history-tr-content td:first-child {
	padding-left: 1rem !important;
}
table .history-tr-header th:last-child,
table.history tr.history-tr-content td:last-child {
	padding-right: 1rem !important;
}
.rotate-icon {
	animation: rotation 8s infinite linear;
}
@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
.panel-table th,
.panel-table td {
	&:nth-child(2),
	&:nth-child(3),
	&:nth-child(4),
	&:nth-child(5),
	&:nth-child(6) {
		width: calc(90% / 5);
		max-width: calc(90% / 5);
	}
}
.container-list {
	position: relative;
	display: flex;
	flex-direction: column;
	height: calc(100% - 50px);
}
div.page-container {
	height: 100%;
	width: calc(100% - 100px);
	font-family: 'Lato', sans-serif;
	display: flex;
	flex-direction: column;
	border-radius: 10px;
	box-shadow: rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.12);
	margin: 30px 50px;
	font-size: 14px;
	.scroll {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		overflow-y: auto;
		height: calc(100% - 65px);
	}
}
.checkpayment-btn {
	font-size: 10px !important;
	max-width: 155px 10px !important;
}
.checkpayment-btn--icon.disabled {
	opacity: 0.6;
	cursor: not-allowed;
}
.checkpayment-btn--icon {
	cursor: pointer;
	text-transform: uppercase;
	color: white;
	font-size: 12px;
	background-color: var(--info);
	margin-right: 5px;
	border: none;
	border-radius: 4px;
	padding: 0 16px;
	width: 100px;
	height: 36px;
	svg path {
		fill: white;
	}
}

.info.checkpayment-btn {
	margin-right: 5px;
	&[disabled=''] {
		background: #14a2b840;
	}
}
// expansion panel section
.wrap-collabsible.selected {
	// order: -1;
	display: flex;
	flex-direction: column;
	opacity: 1;
	// margin-bottom: 25px;
	// margin-top: 25px;
	.lbl-toggle {
		background: radial-gradient(circle at 50% 100%, rgb(23 23 25 / 50%), rgb(8 59 100 / 61%));
		//background:radial-gradient(circle at 50% 100%, #0089db9c, #0770d063);
	}
}
.wrap-collabsible.unselected {
	opacity: 0.65;
}

input[type='checkbox'] {
	display: inline-block;
}
.header .lbl-toggle {
	height: 65px;
}
.lbl-toggle {
	border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.header .lbl-toggle,
#headerpanel {
	border-top: 0;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}
.lbl-toggle {
	display: block;
	padding: 1rem;
	color: white;
	background: transparent;
	position: relative;

	cursor: pointer;
	transition: all 0.25s ease-out;
	.panel-header-container {
		display: flex;
		align-items: center;
		justify-items: center;
		.flex-1 {
			display: flex;
			flex: 1;
			justify-content: center;
			align-content: center;
			align-items: center;
			text-align: center;
			span {
				white-space: nowrap;
			}
			&.nome {
				justify-content: left;
				padding-left: 20px;
				text-align: left;
			}
		}
		.flex-0 {
			display: flex;
			flex: 0px;
			justify-content: center;
			text-align: center;
			align-content: center;
			align-items: center;
		}
		.flex-0.select {
			max-width: 120px;
			min-width: 105px;
		}
	}
}

.collapsible-content {
	max-height: 0px;
	overflow: hidden;
	transition: max-height 0.25s ease-in-out;
}

.toggle:checked + .lbl-toggle + .collapsible-content {
	max-height: 100vh;
}

.collapsible-content .content-inner {
	border-bottom: 1px solid #00dbc350;
	border-top: 1px solid #00dbc350;
	padding: 0.5rem 1rem;
}
.expansion-panel.selected .arrow svg {
	transform: rotate(90deg);
	transition: all 0.2s ease-in-out;
}
.arrow {
	flex: 0 !important;
	min-width: 40px;
	max-width: 40px;
	svg {
		transform: rotate(0deg);
		transition: all 0.2s ease-out;
	}
}
.topHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
}
.table-container {
	border-radius: 5px;
	margin: 10px 50px 55px 50px;
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
	overflow: hidden;
}
.content-table {
	border-radius: 5px;
	margin: 1px;
	background: #1a73a347;
	tr:first-child {
		background: #1a74a3;
	}
	tr th:first-child,
	tr td:first-child {
		text-align: left;
		//width: 20%;
		// max-width: 20%;
		padding-left: 30px;
	}
	tr th,
	tr td {
		text-align: center;
		padding-left: 30px;
		width: calc(100% / 5);
		max-width: calc(100% / 5);
		// border:1px solid #dc3545;
	}
	.badge-euro {
		display: inline-block;
		border: 1px solid #1f88c1;
		background-color: #0cced2c2;
		// background: transparent;
		color: white;
		min-width: 35px;
		height: 35px;
		border-radius: 35px;
		text-align: center;
		line-height: 35px;
		margin: 0 5px;
		font-weight: 600;
		padding: 0 10px;
	}
	.badge-payment {
		display: inline-block;
		border: 1px solid #db5832;
		background: transparent;
		//background-image: linear-gradient(to right, transparent, #db583235, #db583245, #db583248, #db583245, #db583235, transparent);
		color: white;
		min-width: 35px;
		height: 35px;
		border-radius: 5px;
		text-align: center;
		line-height: 35px;
		margin: 0 5px;
		font-weight: 600;
		padding: 0 10px;
		text-transform: uppercase;
		line-height: 35px;
		font-size: 12px;
		&.paid {
			border: 1px solid #1f88c1;
			//background: #8dbc68;
			background: transparent;
			//background-image: linear-gradient(to right, transparent, #1f88c135, #1f88c145, #1f88c148, #1f88c145, #1f88c135, transparent);
		}
	}
}
.dot {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin-right: 5px;
	display: inline-block;

	&.dot-color0 {
		background-color: #ffc107;
	}
	&.dot-color1 {
		background-color: #09ff00;
	}
	&.dot-color2 {
		background-color: #ff4500;
	}
}
.dialog-header {
	margin: -18px 0 9px 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.title {
		color: #a72632;
		font-size: 16px !important;
		font-style: normal;
		font-weight: 400 !important;
		line-height: 18px !important;
		letter-spacing: 0em;
		text-align: left !important;
		margin-bottom: 18px;
	}
	.close {
		position: relative;
	}
}
th:first-child,
td:first-child {
	padding-left: 0.4rem !important;
	padding-right: 0rem !important;
	text-align: right;
}
th:last-child,
td:last-child {
	padding-right: 1.5rem !important;
}
td.chevron svg {
	transform: rotate(0deg);
	transition: all 50ms ease-in;
	&.rotate {
		transform: rotate(90deg);
		transition: all 100ms ease-in;
	}
}
.payment-alert {
	border: 1px solid #ffffff20;
	background: #a4c84d15;
	display: flex;
	align-items: center;
	padding: 0.5rem 0.5rem;
	margin: 1px;
	.payment-badge {
		background: #a4c84d55;
		width: 45px;
		height: 45px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 0.5rem;
	}
}
@media only screen and (max-width: 720px) {
	.topHeader {
		flex-direction: column;
	}
	.buttonsOnTop {
		margin-bottom: 20px;
		width: 100%;
	}
	.lbl-toggle {
		padding: 0rem 1rem;
	}
	.selected {
		margin: 0 !important;
	}
	div.expansion-panel.header.wrap-collabsible {
		display: none;
	}
	.panel-header-container {
		position: relative;
		flex-direction: column;
		div:not(.bordered):not(.bordered *) {
			display: block !important;
			width: 100%;
			max-width: 100% !important;
			position: relative;
			text-align: right !important;
			line-height: 38px;
			min-height: 38px;
			padding: 0 !important;
			word-spacing: 1px !important;
			svg {
				margin: 7px;
			}
			input.select-company {
				margin-top: 12.5px;
			}
			button {
				margin-left: auto;
				margin-right: auto;
			}
		}
		div:nth-of-type(4) {
			margin-top: 5px;
		}
		div:before {
			text-align: left;
			position: absolute;
			display: block;
			width: 50%;
			line-height: 38px;
			min-height: 38px;
		}
		div:nth-of-type(2):before {
			content: 'Nome Azienda';
		}
		div:nth-of-type(3):before {
			content: 'Coid';
		}
		div:nth-of-type(4):before {
			content: 'Euro';
		}
		div:nth-of-type():before {
			content: '';
		}
	}
}

@media (min-width: 601px) and (max-width: 1026px) {
	.nome {
		display: -webkit-box !important;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-left: 5px !important;
	}
	.panel-header-container {
		div:not(.badge):not(.select) {
			min-width: 20%;
		}
		div:nth-of-type(5),
		div:nth-of-type(4) {
			word-spacing: 300px;
		}
	}
}
</style>
