<template>
  <div class="search-container">
    <label v-if="false" class="drop">
      <input id="target-drop-example" type="checkbox">
      <span class="control">All</span>

      <!-- Items -->
      <ul class="drop-items">
        <li class="item-drop">Companies</li>
        <li class="item-drop">Campaigns</li>
        <li class="item-drop">Candidates</li>
        <li class="item-drop">Members</li>
      </ul>

      <!-- Alternative to close dropdown with click out -->
      <label for="target-drop-example" class="overlay-close" />
    </label>
    <input v-model="searchInput" class="search" type="search" :placeholder="getPlaceholder">
  </div>
</template>

<script>
import { computed } from 'vue'
export default {
  props: ['search', 'placeholder'],
  emits: ['update:search'],
  setup(props, { emit }) {
    const searchInput = computed({
      get: () => {
        return props.search
      },
      set: (value) => {
        emit('update:search', value)
      }
    })
    const getPlaceholder = computed(() => {
      return props.placeholder ? props.placeholder : 'Search...'
    })

    return {
      searchInput,
      getPlaceholder
    }
  }
}
</script>

<style>
input[type="search"]::-webkit-search-cancel-button {

 -webkit-appearance: none;
  height: 24px;
  width: 24px;
  margin-left: .4em;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%232f618f'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
  cursor: pointer;
}
</style>
<style lang="scss" scoped>
@media only screen and (max-width: 720px) {
	.search-container {
		width: 100% !important;
		margin: 0 !important;
		margin-bottom: 20px !important;
	}
}
.search-container {
	// width: 65%;
	flex: 1;
	display: flex;
	align-items: center;
	justify-items: center;
	// margin-right: 15px;
	input:focus-visible {
		outline-offset: 0px;
		outline: 0;
	}

	.drop {
		display: inline-block;
		position: relative;
		text-align: center;
	}
	.drop input[type='checkbox'] {
		display: none;
	}
	.drop input[type='checkbox']:checked ~ .drop-items {
		opacity: 1;
		max-height: 400px;
		padding: 0.6rem 0;
	}
	.drop input[type='checkbox']:checked ~ .control {
		z-index: 99;
		box-shadow: 0 0 0 2px #5055b1;
	}
	.drop input[type='checkbox']:checked ~ .control:after {
		transform: rotate(180deg);
	}
	.drop input[type='checkbox']:checked ~ .overlay-close {
		transform: none;
		pointer-events: initial;
	}
	.drop:focus-visible {
		outline: 0;
	}
	.drop .control {
		position: relative;
		color: #fff;
		display: inline-block;
		cursor: pointer;
		transition: all 0.3s ease;
		border: 1px solid rgba(255, 255, 255, 0.15);
		//border-top-left-radius: 5px;
		//border-bottom-left-radius: 5px;
		border-radius: 5px !important;
		height: 43px;
		padding: 0 15px;
		line-height: 43px;
	}
	@media (hover: hover) {
		.drop .control:hover {
			opacity: 0.75;
		}
	}

	.drop .control:after {
		content: '';
		display: inline-block;
		height: 0.5em;
		width: 0.5em;
		margin-left: 0.8em;
		background-image: url("data:image/svg+xml,%3Csvg fill='%23fff' xmlns='http://www.w3.org/2000/svg' width='16' height='13.838' viewBox='0 0 16 13.838'%3E%3Cpath d='M15.78,12.753a1.529,1.529,0,0,1-1.311,2.314H1.53A1.529,1.529,0,0,1,.219,12.753L6.69,1.969a1.528,1.528,0,0,1,2.619,0L15.78,12.753Z' transform='translate(16 15.067) rotate(180)'/%3E%3C/svg%3E%0A");
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		transition: transform 0.1s ease-in;
	}
	.drop .drop-items {
		color: white;
		position: absolute;
		top: calc(100% + 0.4rem);
		width: 100%;
		min-width: 100px;
		padding: 0;
		margin: 0;
		transition: all 0s ease;
		background: #013965;
		box-shadow: 0 2px 6px rgba(254, 253, 253, 0.35);
		border-radius: 4px;
		overflow: hidden;
		max-height: 0;
		opacity: 0;
		z-index: 99;
		border: 1px solid rgba(255, 255, 255, 0.15);
	}
	.drop .drop-items .item-drop {
		list-style: none;
		padding: 5px 0px;
	}

	.drop .overlay-close {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 9;
		transform: translate(100%, 0);
		pointer-events: none;
	}

	.search {
		height: 45px;
		flex: 1;
		width: 100%;
		display: flex;
		color: white;
		background: transparent;
		border: 1px solid rgba(255, 255, 255, 0.15);
		border-radius: 5px;
		padding-left: 15px;
	}
	input::placeholder {
		color: rgba(255, 255, 255, 0.762);
	}
}
</style>